import cx from "classnames";

import { ILogoProps } from "../types";
import { ReactComponent as Icon } from "./logo.svg";
import * as styles from "./styles.module.css";

function Logo({ largeScreen = true }: ILogoProps) {
  return (
    <Icon
      className={cx(styles.logo, {
        [styles.small]: !largeScreen,
      })}
    />
  );
}

export { Logo };
